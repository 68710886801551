/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-30 16:20:34
 * @Module: 公共底部
 */
 <template>
  <footer class="deffooter"
          v-show="show">
    <div class="footer-content">
      <div class="footer-content-l">
        <a href="https://beian.miit.gov.cn/"
           style="color:#666;"
           target="_blank">豫ICP备19039737号 中能魔力（河南）网络科技有限公司</a>
        <a href="https://oss.mcn-open.com/images/web-culture-licence.png"
           style="color:#666;margin-left:26px"
           target="_blank">豫网文〔2020〕3714-232号</a>
      </div>
      <div class="footer-content-r">
        <img src="@/static/homepage/Lark20210106-160955.png"
             class="icon">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41030502000250"
           style="color:#666;"
           target="_blank">
          豫公网安备 41030502000250号</a>
        <!-- <span>服务时间：周一至周六 12:00-22:00</span> -->
        <span>违法和不良信息举报：400-119-3009</span>
        <span>公司地址：洛阳市涧西区世纪中心21楼</span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data () {
    return {
      hidden_footer: ['about', 'certification', 'college'],   //隐藏默认底部的页面
      show: true
    };
  },
  mounted () { },
  methods: {},
  watch: {
    $route: {
      handler: function (val) {
        const now_name = val.path.split("/")[1]
        this.show = !this.hidden_footer.includes(now_name)
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang='scss' scoped>
.deffooter {
  background: #f7f7f7;
  cursor: default;
}

.footer-content {
  width: 1240px;
  margin: 0 auto;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  color: #666;
  align-items: center;
  &-l {
    span {
      margin-left: 20px;
    }
  }
  &-r {
    display: flex;
    align-items: center;
    span {
      margin-left: 20px;
    }
    .icon {
      display: inline;
      margin-right: 10px;
      margin-top: -2px;
    }
  }
}
</style>